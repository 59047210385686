import axios from 'axios'
import { getToken } from 'helpers/auth'

export const __API__ = process.env.REACT_APP_API_URL

const defaultOptions = {
  baseURL: __API__,
}

let instance = axios.create(defaultOptions)

instance.interceptors.request.use(config => {
  const token = getToken()

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

instance.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401 && window.location.pathname !== '/login') {
      window.location.href = '/404'
    } else {
      return Promise.reject(error)
    }
  },
)

export default instance
